






























import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineELN4OIProcedure2',
  components: {STextarea, StembleLatex, TableInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        rows: [{procedure: '', observations: ''}],
      },
      columns: [{label: 'Procedure', component: STextarea, prop: 'procedure'}],
      questions: [
        {
          text: 'In the space below, please write the sample dilution concentration calculation.',
          inputName: 'additionalObservations',
        },
      ],
    };
  },
});
